import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { editedImagesAi, producedImagesAi, } from 'redux/actions/order-actions';
import { Modal, OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import { orderService } from '../../../services/order';
import { LazyLoadImage } from 'react-lazy-load-image-component';
class SimilarImagesAi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            similarImages: [],
            parentImage: {}
        };
    }

    componentDidMount() {
        this.getSimilarImages();
    }

    componentDidUpdate(prevProps, prevState) {
        
    }

    getSimilarImages = () => {
        const data = {
            order_id: this.props.order_id,
            image_id: this.props.parentImage.id
        }
        orderService.getSimilarImages(data).then((response) => {
            console.log("res", response);
            
            this.setState({ similarImages: response.data.similar_images, parentImage: response.data.parent_image });
        })
    }

    openImage = (path) => {
        const link = document.createElement('a');
        link.href = path;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
    }
      
    formatDate = (dateString) => {
        if (dateString && this.isValidDate(dateString)) {
            const date = new Date(dateString.replace(" ", "T")); // Convert to ISO format if necessary
            const options = { month: 'short', day: '2-digit', year: 'numeric' };
            const datePart = new Intl.DateTimeFormat('en-US', options).format(date); // Format date
            const timePart = date.toTimeString().split(" ")[0]; // Extract time as HH:mm:ss

            return `${datePart} ${timePart}`;
        }
    };
    
    isValidDate = (date) => {
        let tempDate = new Date (date);
        return tempDate instanceof Date && !isNaN(tempDate.getTime());
    }

    setTempValue = (image) => {
        this.setState((prevState) => {

            // Insert the new image object at the specified position
            let similarImages = [...prevState.similarImages];

            // updating in_appropriate value
            similarImages = similarImages.map((item, index) => {
                if (item.id === image.id) {
                    console.log("image.use_in_full_video",image.use_in_full_video);
                    
                    return { ...item, use_in_full_video: Number(!image.use_in_full_video) };
                    // return { ...item, use_in_full_video: image.use_in_full_video ? 0 : 1 };
                }
                return item;
            });

            return {
                similarImages: similarImages,
            };
        });
    }
    render() {
        const { similarImages, parentImage } = this.state;
        const { openSimilarImageModal } = this.props;
        
        return (
            <div className=''>
                <Modal bsSize="lg"  backdrop={'static'} show={openSimilarImageModal} id="" onHide={this.props.onHide} aria-labelledby="ModalHeader" className="similarImgModal" >
                    <Modal.Header closeButton>
                        <Modal.Title id='ModalHeader' className="headerTitle">Similar Images</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="similar-img-grid">   
                            {
                                parentImage &&
                                <div className="similar-img-rows" style={{backgroundColor: parentImage.use_in_full_video ? 'lemonchiffon' : '#f4cfdf'}}>
                                    {
                                        parentImage.use_in_full_video === 1 ?
                                        <div style={{margin: '5px', cursor: 'pointer'}} onClick={() => {
                                            this.props.handleSetAiImageFlag(parentImage, 'use_in_full_video', true);
                                            }}>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Do not include in video</Tooltip>}>
                                                <i style={{fontSize: '15px', width: '100%'}} className="fa fa-ban"
                                                aria-hidden="true"></i>
                                            </OverlayTrigger>
                                        </div>
                                        :
                                        <div className='mb-sm mt-sm' style={{cursor: 'pointer'}} onClick={() => {
                                            this.props.handleIncludeInFullVideo(parentImage, 'use_in_full_video', true)
                                            }}>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Include in video</Tooltip>}>
                                                <i style={{fontSize: '15px', width: '100%'}} className="fa fa-check"
                                                aria-hidden="true"></i>
                                            </OverlayTrigger>
                                            {/* <div>similarity {parentImage.similarity_percentage ? (parentImage.similarity_percentage * 100) + '%' : 'unknown'}</div> */}
                                            <span className="mt-sm" >This image omitted by user</span>
                                        </div>
                                    }
                                    <LazyLoadImage
                                        section='parent-image'
                                        src={parentImage && parentImage.resized_image_path && parentImage.resized_image_path.replace('SIZE', 'thumb_xlarge') + "?updated_at=" + parentImage.updated_at}
                                        alt='image'
                                        // onClick={(e) => {this.props.selectImageHandler(e, parentImage)}}
                                        onClick={() => {this.openImage(parentImage.edited_image_path + "?updated_at=" + parentImage.updated_at)}}
                                        className='image-style-similar'
                                        placeholder={<div><img src={"/images/loading200.gif"} alt="Loading..." /></div>}
                                        visibleByDefault={parentImage.src === '/images/' + parentImage.placeholder}
                                        effect="blur"
                                        threshold='1'
                                    />

                                    <div className='image-btn' >
                                        <span className='position-ai'>Video Position {+parentImage.producer_sort_order }</span>
                                        
                                    </div>
                                    <div className="galleryInfo p-sm">
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip id={parentImage.id}>
                                                Date
                                            </Tooltip>
                                        }>
                                            <span className='text-muted dark text_14'>{parentImage.exif_date_taken}</span>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="bottom" overlay={
                                            <Tooltip id={parentImage.id}>
                                                Photo Name
                                            </Tooltip>
                                        }>
                                            <span className='galleryId text_14'>{parentImage.id}</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            }                     
                           {similarImages.map((image, i) => {
                                return (
                                    <div className="similar-img-rows" style={{backgroundColor: image.use_in_full_video ? 'lemonchiffon' : (image.duplicate_group_of <= 1 ?'pink' : '')}}>

                                        {image.use_in_full_video === 1 ?
                                            <div style={{margin: '5px', cursor: 'pointer'}} onClick={() => {
                                                this.props.handleSetAiImageFlag(image, 'use_in_full_video', true);
                                                this.setTempValue(image);
                                                }}>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Do not include in video</Tooltip>}>
                                                    <i style={{fontSize: '15px', width: '100%'}} className="fa fa-ban"
                                                    aria-hidden="true"></i>
                                                </OverlayTrigger>
                                            </div>
                                        :
                                            image.duplicate_group_of <= 1 ?
                                                <div className='mb-sm mt-sm' style={{cursor: 'pointer'}} onClick={() => {
                                                    this.props.handleIncludeInFullVideo(image, 'use_in_full_video', true);
                                                    this.setTempValue(image);
                                                    }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Include in video</Tooltip>}>
                                                        <i style={{fontSize: '15px', width: '100%'}} className="fa fa-check"
                                                        aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    <span className="mt-sm" >AI selected image omitted by producer</span>
                                                </div>
                                            :
                                                <div className='mb-sm mt-sm' style={{cursor: 'pointer'}} onClick={() => {
                                                    this.props.handleIncludeInFullVideo(image, 'use_in_full_video', true);
                                                    this.setTempValue(image);
                                                    }}>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Include in video</Tooltip>}>
                                                        <i style={{fontSize: '15px', width: '100%'}} className="fa fa-check"
                                                        aria-hidden="true"></i>
                                                    </OverlayTrigger>
                                                    <div>similarity {image.similarity_percentage ? (image.similarity_percentage * 100).toFixed(2) + '%' : ''}</div>
                                                </div>
                                    }
                                        

                                        <LazyLoadImage
                                            section='similar-images'
                                            src={image.resized_image_path.replace('SIZE', 'thumb_xlarge') + "?updated_at=" + image.updated_at}
                                            alt='image'
                                            // onClick={(e) => {this.props.selectImageHandler(e, image)}}
                                            onClick={() => {this.openImage(image.edited_image_path + "?updated_at=" + image.updated_at)}}
                                            className='image-style-similar'
                                            placeholder={<div><img src={"/images/loading200.gif"} alt="Loading..." /></div>}
                                            visibleByDefault={image.src === '/images/' + image.placeholder}
                                            effect="blur"
                                            threshold='1'
                                        />
                                        <div className='image-btn' >
                                            {
                                                image.use_in_full_video === 1 ?
                                                    <span className='position-ai'>Video Position {+image.producer_sort_order }</span>
                                                :
                                                    <span className='position-ai'>Omitted Position {+image.user_sort_order }</span>
                                            }
                                        </div>
                                        <div className="galleryInfo p-sm">
                                            <OverlayTrigger placement="bottom" overlay={
                                                <Tooltip id={image.id}>
                                                    Date
                                                </Tooltip>
                                            }>
                                                <span className='text-muted dark text_14'>{this.formatDate(image.exif_date_taken)}</span>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="bottom" overlay={
                                                <Tooltip id={image.id}>
                                                    Photo Name
                                                </Tooltip>
                                            }>
                                                <span className='galleryId text_14'>{image.id}</span>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                );
                            })}                        
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
  
export default SimilarImagesAi;